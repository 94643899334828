import * as ga4mp from '@project-gd-x/shared-web/src/helpers/ga4mp/ga4mp';

import { getUserId } from '@/helpers/auth/auth';
import { parseCookie } from '@/helpers/cookie/cookie';
import { pushRealtimeTrackingCounter } from '@/services/realtime-tracking/realtime-tracking';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

type RegEvents =
  | 'reg_intro'
  | 'reg_eye_color'
  | 'reg_hair_color'
  | 'reg_interests'
  | 'reg_registration'
  | 'reg_relationship'
  | 'reg_bodytype'
  | 'reg_age_prefer'
  | 'reg_we_found'
  | 'reg_message'
  | 'reg_name'
  | 'reg_birthdate'
  | 'reg_password'
  | 'reg_meet'
  | 'reg_email'
  | 'reg_reviews';

export type PromoType =
  | 'top-profile-new'
  | 'top-profile-plus'
  | 'premium'
  | 'credits'
  | 'personality';

type MyProfilePromo = {
  event: `my-profile-promo-${PromoType}`;
  block: 'my-profile';
};

type SigninResult = {
  event: 'signin-result';
  result: 'success' | 'fail';
  remember: boolean;
};

type SigninRestorePasswordSent = {
  event: 'signin-restore-password-sent';
};

type SignupAccountAccountCreated = {
  event: 'signup-account-created';
};

type SignupMyProfileLocation = {
  event: 'signup-my-profile-location';
  value: string;
};

type SignupMyProfilePhoto = {
  event: 'signup-my-profile-photo';
  isFirstPhoto: boolean;
};

type SignupEngagementProfiles = {
  event: 'signup-engagement-profiles';
  unlikedCount: string;
};

type MyProfileEditPhoto = {
  event: 'my-profile-edit-photo';
};

type MyProfileEditInfo = {
  event: 'my-profile-edit-info';
};

export type PaymentPage =
  | 'promo'
  | 'premium'
  | 'premium-gold'
  | 'credits'
  | 'payment'
  | 'payment-fail';

type Settings = {
  event: 'settings';
  section:
    | 'account'
    | 'privacy'
    | 'policies'
    | 'blocked'
    | 'subscription'
    | 'credits'
    | 'faq'
    | 'support'
    | 'blog';
};

type SettingsPrivacy = {
  event: 'settings-privacy';
  online: boolean;
  search: boolean;
};

type SettingsPaymentMethod = {
  event: 'settings-payment-method';
};

type ChatSendTextMessage = {
  event: 'chat-send-text-message';
};

type ChatSendImageMessage = {
  event: 'chat-send-image-message';
};

type ChatSendGiftMessage = {
  event: 'chat-send-gift-message';
};

type RetentionReward = {
  event: 'retention-reward';
  type: 'close-modal' | 'claim' | 'timer' | 'no-timer' | 'close-notification';
};

export type PromoShowPurchaseSource =
  // 5 seconds timeout after onboarding page
  | 'timeout-after-onboarding'
  // click any search filter
  | 'search-filters'
  // click `unlock` button on search filters
  | 'search-filters-unlock'
  // backend sent command to show promo
  | 'backend'
  // click delete own message in chat
  | 'chat-delete-message'
  // tries send message in chat
  | 'chat-send-message'
  // click `unlock` button on profile page
  | 'profile-unlock'
  // click `unblock` button on profile page
  | 'profile-unblock'
  // tries to like someone on profile page
  | 'profile-insufficient-likes'
  // tries to like someone on search page
  | 'search-insufficient-likes'
  // tries to like someone on activity page
  | 'activity-insufficient-likes'
  // popup has been shown by direct link `/search/promo`
  | 'direct-link'
  // popup has been shown from search page ( trial card item ).
  | 'search-grid'
  // clicked somewhere if activity ( incoming / outgoing ) page has been locked
  | 'activity_incoming'
  | 'activity_outgoing'
  // clicked on chat with suspicious content
  | 'chat-suspicious-content'
  // click on purchase button in LockedPhotoOverlay.vue
  | 'locked-photo-overlay'
  | 'limited-offer-credits-pack-search-grid'
  // /payment/success page if you buy credits we show trial offer, if you buy trial we show credits offer
  | 'payment-success'
  // profile tags
  | 'profile-tags'
  // route from mail link.
  | 'mail'
  // when call showPremiumGoldModal from proper source.
  | 'premium-gold-credits-success-websocket'
  | 'premium-gold-search-snack'
  | 'premium-gold-my-profile'
  // waiting for free gift modal ( with button upgrade for boy trial ).
  | 'chat-free-gift'
  // click on prolongation icon on particular page.
  | 'prolongation-icon-activity'
  | 'prolongation-icon-chat-list'
  | 'prolongation-icon-search'
  // show on route change ( callProlongationModal in router.ts ).
  | 'prolongation-route'
  | 'prolongation-modal-expired'
  | 'prolongation-modal-credits-bonus-trial'
  | 'prolongation-modal-expiring'
  // Blurred message in chat.
  | 'chat-blurred-message'
  // TODO: discover this source
  | 'feature-limit'
  | 'my-profile'
  | 'settings'

  // https://gdx.myjetbrains.com/youtrack/issue/GDP-3790/frontend-Trial-Expiration-Reminder-Welcome-Popup
  | 'reminder';

type SettingsAccountDeleteComplete = {
  event: 'settings-settings-delete-account-flow-modal-complete';
};

type WebPushNotificationsPermission = {
  event: 'web-push-notifications-permission';
  result: boolean;
};

// Booster promo popup has been shown to user.
type BoosterPromoShow = {
  event: 'booster-promo-show';
};

// User successfully activated promo booster.
type BoosterPromoSuccess = {
  event: 'booster-promo-success';
  boosterTitle: string;
  boosterPrice: string;
};

// User tried to buy credits, but payment was failed.
type BoosterPromoFail = {
  event: 'booster-promo-fail';
};

// User purchased more credits, but it's still not enough to activate booster.
type BoosterPromoRetry = {
  event: 'booster-promo-retry';
};

// User see credits page.
type ShowCreditsPage = {
  event: 'show-credits-page';
  block: 'my-profile-direct' | 'my-profile-top-profile' | 'top-profile-promo-popup' | 'chat-gift';
};

type PurchaseCreditsOneTimeOffer = {
  event: 'purchase-credits-one-time-offer';
  block: PromoShowPurchaseSource;
};

type CheckCompatibility = {
  event: 'check-astrology-compatibility';
  block: 'user-profile';
};

type ShowCreditsOneTimeOffer = {
  event: 'show-credits-one-time-offer';
  block: 'payment-success';
};

export type TrackPaymentEventType = 'premium' | 'credits';
export type TrackPaymentEventPopup =
  | 'premium-trial' // Стандартный trial попап.
  | 'premium-welcome-trial' // Welcome попап ( если их несколько в сплите, то все будут с таким ивентом ).
  | 'premium-welcome-trial-v2' // Welcome попап v2 ( https://gdx.myjetbrains.com/youtrack/issue/GDP-3390/Payment-screen-popup-lp )
  | 'premium-discount' // Скидочный попап.
  | 'premium' // Когда попап триггернулся, но никаких промо нету - по факту юзера средиректит на /payment/purchase-premium.
  | 'trial-one-time-offer' // Попап який пропонуємо після покупки кредитів, типо докупити ще й тріал ( SubscriptionPromoTrialOneTimeOffer.vue )
  | 'credits-one-time-offer' // Разовая скидка на кредиты ( срабатывает на странице /payment/success ) ( https://gdx.myjetbrains.com/youtrack/issue/GDP-1381 ).
  | 'credits-limited-offer-pack' // Скидка -50% на кредиты ( https://gdx.myjetbrains.com/youtrack/issue/GDP-1459 ).;
  | 'chat-buy-free-gift-modal' // Попап з таймером фрі гіфта та кнопкою апгрейду на преміум src/pages/chat/components/chat-view/components/chat-buy-free-gift-modal/ChatBuyFreeGiftModal.vue
  | 'premium-gold-promo' // Попап преміум голд промо  src/components/premium-gold-promo-modal/PremiumGoldPromoModal.vue
  | 'my-profile-deactivated-mail' // Компонент що відображається по роутут при переході з мейла src/pages/my-profile/components/my-profile-deactivated-mail/MyProfileDeactivatedMail.vue
  | 'purchase-credits-one-time-offer' // show after trial buy src/pages/payment/components/purchase/components/purchase-credits/components/purchase-credits-one-time-offer/PurchaseCreditsOneTimeOffer.vue
  | 'prolongation-modal-credits-bonus-trial'
  | 'prolongation-modal-expiring'
  | 'prolongation-modal-expired'
  | 'non-trial-funnel-popover' // Non trial funnel popover modal ( insufficient likes / messages ).
  | 'non-trial-funnel' // Non trial funnel promo.
  | 'non-trial-funnel-welcome'

  // Ntf v2.1
  | 'welcome-trial-ntf-v1'
  | 'trial-ntf-v1'
  | 'prosperi-ntf-v1'
  | 'welcome-trial-ntf-v2'
  | 'premium-ntf-v2'

  // Other
  | string;

export type TrackPaymentEvent = {
  event: 'promo';
  type: TrackPaymentEventType;
  from: PromoShowPurchaseSource;
  popup: TrackPaymentEventPopup;
  chainId: string;
};

export type TrackPaymentFlowStep = 'list' | 'add' | 'add-retry' | 'solid-form';

export type TrackPaymentFlow = {
  event: 'payment-flow';
  step: TrackPaymentFlowStep;
  chainId: string;
};

export type TrackPaymentClickType = 'regular-flow' | 'fail-page';
export type TrackPaymentClickMethod =
  | 'solid-form'
  | 'paypal'
  | 'apple-pay'
  | 'google-pay'
  | 'resign'
  | 'token';

export type TrackPaymentClick = {
  event: 'payment-click';
  type: TrackPaymentClickType;
  path: string;
  chainId: string;
};

export type TrackPaymentResultType = 'success-page' | 'fail-page';
export type TrackPaymentResult = {
  event: 'payment-result';
  type: TrackPaymentResultType;
  orderId: string;
  chainId: string;
};

type SigninMethod = {
  event: 'signin_method';
  name: 'google' | 'facebook' | 'email';
};

type SignupMethod = {
  event: 'reg_method';
  name: 'google' | 'facebook' | 'email';
};

type SigninEvent = {
  event: 'signIn';
};

type SignupStepGender = {
  event: 'reg_gender';
  step: string;
  gender: 'male' | 'female';
};

type SignupStepMeet = {
  event: 'reg_meet';
  step: string;
  gender: 'male' | 'female';
};

type SignupStep = {
  event: RegEvents;
  step: string;
};

type SignupSuccess = {
  event: 'reg_success';
  regMethod: 'google' | 'facebook' | 'email';
  regTimestamp: string;
  userId: string;
};

type SignupError = {
  event: 'error';
  errorCode: unknown;
  errorMessage: string;
};

export type OnboardingEvents =
  | 'onbording_photo'
  | 'onbording_location'
  | 'onbording_describe'
  | 'onbording_finish';

type SignupOnboarding = {
  event: OnboardingEvents;
};

type ShowBoosterUpsale = {
  event: 'show-booster-upsale';
  block: PromoShowPurchaseSource;
};

type ShowPremiumDiscountPromo = {
  event: 'split_show';
  split_title: 'fiftyPremiumDiscount_1_v1';
  click_source: string | symbol;
};

type ClickPremiumDiscountPromo = {
  event: 'split_click';
  split_title: 'fiftyPremiumDiscount_1_v1';
};

type ActivityProfileClick = {
  event: 'activity_profile_click';
  user_id: string;
  page_type: string;
};

type ActivityNotificationLikeClick = {
  event: 'activity_notification_like_click';
  user_id: string;
  page_type: string;
};

type ActivitySeeWhoClick = {
  event: 'activity_see_who_click';
  user_id: string;
  page_type: string;
};

type ActivityUnlockHistoryClick = {
  event: 'activity_unlock_history_click';
  user_id: string;
  page_type: string;
};

type PowerLikeClicked = {
  event: 'powerlike_clicked';
  user_id: string;
};

type PowerLikeShown = {
  event: 'powerlike_show';
  user_id: string;
};

type PowerLikeSent = {
  event: 'powerlike_sent';
  user_id: string;
};

type PowerLikeReply = {
  event: 'powerlike_replay';
  user_id: string;
};

type UnusedCreditsPopup = {
  event: `unused-credits-popup-${'close' | 'show'}`;
  user_id: string;
};

type UnusedCreditsButton = {
  event: `unused-credits-button-${'close' | 'show' | 'click'}`;
  user_id: string;
};

type PhotoUploadReward = {
  event: 'photo-upload-reward-astro-click';
  user_id: string;
  count_photos: string;
};

type RequestPhoto = {
  event: 'request-photo';
};

type ClickAstroCompatibility = {
  event: 'click-astro-compatibility';
};

type ClickSexualCompatibility = {
  event: 'click-sexual-compatibility';
};

type PromoShowPremiumGold = {
  event: 'promo-show-premium-gold';
  block: string;
};

type AgeVerification = {
  event: `age_verification_${
    | 'snack_button_click'
    | 'icon_active_click'
    | 'icon_inactive_click'
    | 'add_card_click'
    | 'card_add_success'
    | 'card_add_fail'}`;
  user_id: string;
};

type FacebookButtonClick = {
  event: 'create_account_facebook';
};

type AutoTopUpShow = {
  event: 'auto-top-up-show';
};

type EarlyUpgradeUpsale = {
  event: `early-upgrade-upsale-${'show' | 'close' | 'purchase'}`;
};

type EngagementProfileRedesign = {
  event: `signup-engagement-redesign-${'full-like' | 'dropped-like'}`;
};

type AnyEvent = {
  event: string;
  [key: string]: string;
};

type AllEvents =
  | EngagementProfileRedesign
  | EarlyUpgradeUpsale
  | AutoTopUpShow
  | FacebookButtonClick
  | AgeVerification
  | PowerLikeClicked
  | PowerLikeShown
  | PowerLikeSent
  | PowerLikeReply
  | ShowBoosterUpsale
  | SignupStepMeet
  | SignupOnboarding
  | SignupError
  | SignupSuccess
  | SignupStep
  | SignupMethod
  | CheckCompatibility
  | SigninEvent
  | SignupStepGender
  | SigninResult
  | SigninRestorePasswordSent
  | SignupAccountAccountCreated
  | SignupMyProfileLocation
  | SignupMyProfilePhoto
  | SignupEngagementProfiles
  | MyProfileEditPhoto
  | MyProfileEditInfo
  | Settings
  | SettingsPrivacy
  | ChatSendTextMessage
  | ChatSendImageMessage
  | ChatSendGiftMessage
  | WebPushNotificationsPermission
  | SettingsAccountDeleteComplete
  | BoosterPromoShow
  | BoosterPromoSuccess
  | BoosterPromoFail
  | BoosterPromoRetry
  | PurchaseCreditsOneTimeOffer
  | ShowCreditsOneTimeOffer
  | ShowCreditsPage
  | ClickPremiumDiscountPromo
  | ShowPremiumDiscountPromo
  | SigninMethod
  | ActivityProfileClick
  | ActivityNotificationLikeClick
  | ActivitySeeWhoClick
  | MyProfilePromo
  | UnusedCreditsPopup
  | UnusedCreditsButton
  | ActivityUnlockHistoryClick
  | PhotoUploadReward
  | SettingsPaymentMethod
  | RetentionReward
  | RequestPhoto
  | ClickAstroCompatibility
  | ClickSexualCompatibility
  | PromoShowPremiumGold
  | TrackPaymentEvent
  | TrackPaymentFlow
  | TrackPaymentClick
  | TrackPaymentResult
  | AnyEvent;

export type GtmTriggers<T extends { event: string } = AllEvents> = T;

type CustomEventTriggerOptions = {
  trackAllLabels?: boolean;
  sendOnce?: boolean;
};

const COOKIE_NAME_CID = '_cid';
const GA4MP_ID = 'G-PQX2YT02L3';

function isAnalyticsAllowed() {
  return +import.meta.env.VUE_APP_IS_GTM_ENABLED && import.meta.env.MODE !== 'test';
}

export function initAnalytics() {
  if (isAnalyticsAllowed()) {
    const cookieList = parseCookie(document.cookie);

    ga4mp.loadGa4mp(GA4MP_ID, {
      clientId: cookieList[COOKIE_NAME_CID] ?? '',
      userId: getUserId(),
    });
  }
}

function getEventNameWithSuffixIfDev(eventName: string) {
  if (import.meta.env.VUE_APP_ENV === 'dev') {
    return `${eventName}_dev`;
  }

  return eventName;
}

export function customEventTrigger(event: GtmTriggers, options?: CustomEventTriggerOptions) {
  const { event: eventName, ...eventParamList } = event;
  if (isAnalyticsAllowed()) {
    // Be aware, gtm can change event object, so we need to copy it
    // window.dataLayer.push(Object.assign({}, event));
    try {
      ga4mp.sendGa4mpEvent(getEventNameWithSuffixIfDev(eventName), eventParamList);
    } catch (e) {
      console.log('ga4mp.sendGa4mpEvent', eventName, eventParamList);
    }
  } else {
    console.log('dataLayer.push', event);
  }

  if (import.meta.env.MODE !== 'test') {
    convertAndPushRealtimeTracking(event, options);
  }
}

function convertAndPushRealtimeTracking(event: GtmTriggers, options?: CustomEventTriggerOptions) {
  let labels: Record<string, string> | undefined;

  if (options?.trackAllLabels) {
    labels = Object.assign({}, event as Record<string, string>);
    if (labels && labels.event) {
      delete labels.event;
    }
  } else {
    switch (event.event) {
      case 'promo':
        labels = {
          popup: event.popup,
          from: event.from,
        };
        break;
    }
  }

  pushRealtimeTrackingCounter(event.event, labels, 1, {
    sendOnce: options?.sendOnce,
  });
}
