import { I18n } from '@/services/i18n';

type Message = {
  header3: string;
  text3: string;
  header4: string;
  text4: string;
};

const messages: I18n<Message> = {
  en: {
    header3: 'Day 25',
    text3: 'Get a reminder when your first month <br> ends.',
    header4: 'Day 30',
    text4: 'Your Premium will automatically renew, <br>cancel anytime before.',
  },
  es: {
    header3: 'Día 25',
    text3: 'Recibe un recordatorio cuando termine <br> tu primer mes.',
    header4: 'Día 30',
    text4: 'Tu Premium se renovará automáticamente, <br> cancela en cualquier momento antes.',
  },
};

export { messages };
