<template>
  <ModalPortal :is-teleport-enabled="false" :background-scroll="false">
    <div class="modal-wrapper modal-backdrop modal_row" style="display: block">
      <div class="modal-dialog modal-dialog-full h-full" role="document">
        <div class="modal-content h-full p-6 pt-4 overflow-y-auto" data-test-id="prosperi_popup">
          <div class="flex flex-col relative w-full h-full">
            <div class="flex-1">
              <div>
                <img
                  data-test-id="close_btn_prp"
                  class="mb-4"
                  src="../../../../../../../../assets/img/ic_close.svg?url"
                  width="24"
                  alt="close"
                  @click="emit('close')"
                />
              </div>

              <!-- Counter start -->
              <div class="flex mb-4">
                <div class="flex flex-col items-center" data-test-id="timer_block_top_prp">
                  <div class="text-strongcaption font-medium">{{ t('expiresIn') }}</div>
                  <!--                <div class="text-headline5 text-error font-semibold">00:00:00</div>-->
                  <div class="flex text-headline5 text-error font-semibold text-center">
                    <div>
                      {{ self.timeLeftFormatted.hh }}
                      <div class="text-overline text-dark-200 font-medium leading-3">hr</div>
                    </div>
                    <div>:</div>
                    <div>
                      {{ self.timeLeftFormatted.mm }}
                      <div class="text-overline text-dark-200 font-medium">min</div>
                    </div>
                    <div>:</div>
                    <div>
                      {{ self.timeLeftFormatted.ss }}
                      <div class="text-overline text-dark-200 font-medium">sec</div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-1 justify-end items-center">
                  <SpinnerButton
                    v-if="self.cardDefault"
                    v-model:is-loading="self.isButtonLoading"
                    data-test-id="try_btn_top_prp"
                    :loading-duration-ms="10000"
                    class="btn h-12 btn-premium"
                    @click="self.purchasePkg()"
                  >
                    {{ t('tryNow') }}
                  </SpinnerButton>
                  <PrimaryButton
                    v-else
                    data-test-id="try_btn_top_prp"
                    class="h-12 btn-premium"
                    @click="self.purchasePkg()"
                  >
                    {{ t('tryNow') }}
                  </PrimaryButton>
                </div>
              </div>
              <!-- Counter end -->

              <template v-if="type === 'welcome'">
                <h1 class="text-headline6 text-premium font-semibold text-center">
                  {{ t('welcome') }}, {{ myProfileFull.name }}
                </h1>
                <h1
                  class="text-headline6 text-dark font-semibold text-center"
                  v-html="t('personalOffer')"
                ></h1>

                <ul
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">
                    {{ t('li1') }}
                  </li>
                  <li class="mb-3 pl-2">
                    {{ t('li2') }}
                  </li>
                  <li class="pl-2">
                    {{ t('li3') }}
                  </li>
                </ul>
              </template>
              <template v-else-if="type === 'trial'">
                <h1
                  class="text-headline6 text-dark font-semibold text-center"
                  v-html="t('try7days')"
                ></h1>

                <ul
                  data-test-id="description_prp"
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">{{ t('trial1') }}</li>
                  <li class="mb-3 pl-2">
                    {{ t('trial2') }}
                  </li>
                  <li class="pl-2">
                    {{ t('trial3') }}
                  </li>
                </ul>
              </template>
              <template v-else-if="type === 'like'">
                <h1
                  data-test-id="title_prp"
                  class="text-headline6 text-error font-semibold text-center"
                >
                  {{ t('like1') }}
                </h1>
                <h2
                  data-test-id="subtitle_prp"
                  class="text-headline7 text-dark font-medium text-center"
                >
                  {{ t('like2') }}
                </h2>

                <ul
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">
                    {{ t('like3') }}
                  </li>
                  <li class="mb-3 pl-2">{{ t('like4') }}</li>
                  <li class="pl-2">
                    {{ t('like5') }}
                  </li>
                </ul>
              </template>
              <template v-else-if="type === 'message'">
                <h1
                  data-test-id="title_prp"
                  class="text-headline6 text-error font-semibold text-center"
                >
                  {{ t('message1') }}
                </h1>
                <h2
                  data-test-id="subtitle_prp"
                  class="text-headline7 text-dark font-medium text-center"
                >
                  {{ t('message2') }}
                </h2>

                <ul
                  data-test-id="description_prp"
                  class="list-image-checkmarkGreen mt-4 pl-5 text-strongcaption text-dark font-medium"
                >
                  <li class="mb-3 pl-2">
                    {{ t('message3') }}
                  </li>
                  <li class="mb-3 pl-2">
                    {{ t('message4') }}
                  </li>
                  <li class="pl-2">
                    {{ t('message5') }}
                  </li>
                </ul>
              </template>

              <div class="flex gap-2 mt-4">
                <div
                  data-test-id="looking_for_prp"
                  class="flex-1 rounded-lg bg-dark-500 p-2 text-center"
                >
                  <div class="text-overline text-dark-100 font-medium">
                    {{ t('lookingForSomething') }}
                  </div>
                  <div class="text-headline7 text-dark font-medium mt-1">
                    ❤️ {{ t('lookingFor.' + self.lookingFor.toLowerCase()) }}
                  </div>
                </div>
                <div data-test-id="age_prp" class="flex-1 rounded-lg bg-dark-500 p-2 text-center">
                  <div class="text-overline text-dark-100 font-medium">{{ t('yourAge') }}</div>
                  <div class="text-headline7 text-dark font-medium mt-1">
                    🔥 {{ myProfileFull.age }}
                  </div>
                </div>
              </div>
            </div>
            <div data-test-id="footer_prp">
              <div>
                <div class="bg-premium h-7 flex justify-center items-center rounded-t-lg">
                  <img src="./images/logo.svg?url" class="h-5 w-5" alt="" />
                </div>
                <div
                  class="flex p-4 pb-3 border-2 border-t-0 rounded-b-lg border-dark-500 items-end"
                >
                  <div data-test-id="description_footer_prp" class="flex-1">
                    <span class="text-button2 text-dark font-semibold">{{
                      promoTrial.pkg.title
                    }}</span>
                    <span class="ml-1 text-button1 text-success font-semibold"
                      >{{ t('only') }} {{ promoTrial.price }}</span
                    >
                    <br />
                    <span class="text-button2 text-error font-semibold">{{ t('expiresIn') }}</span>
                  </div>
                  <div
                    data-test-id="timer_footer_prp"
                    class="text-tag text-secondary font-medium p-2 bg-secondary-500 rounded-lg"
                  >
                    {{ self.timeLeftFormatted.hh }}:{{ self.timeLeftFormatted.mm }}:{{
                      self.timeLeftFormatted.ss
                    }}
                  </div>
                </div>
              </div>

              <SpinnerButton
                v-if="self.cardDefault"
                v-model:is-loading="self.isButtonLoading"
                data-test-id="try_now_footer_prp"
                :loading-duration-ms="10000"
                class="btn h-12 btn-premium w-full mt-3"
                @click="self.purchasePkg()"
              >
                {{ t('tryNow') }}
              </SpinnerButton>
              <PrimaryButton
                v-else
                data-test-id="try_now_footer_prp"
                class="h-12 btn-premium w-full mt-3"
                @click="self.purchasePkg()"
              >
                {{ t('tryNow') }}
              </PrimaryButton>

              <div v-if="!self.isFromLanding" class="text-overline text-dark-200 text-center mt-3">
                <PaymentMethodPolicyTrial
                  :price-next="promoTrial.nextPackagePrice"
                  :price="promoTrial.price"
                  :btn-text="t('tryNow')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import { PropType, reactive } from 'vue';

import PrimaryButton from '@/components/inputs/button/PrimaryButton.vue';
import SpinnerButton from '@/components/inputs/spinner-button/SpinnerButton.vue';
import ModalPortal from '@/components/modal/ModalPortal.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { TrialProsperiType } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const props = defineProps({
  myProfileFull: {
    type: Object as PropType<MyProfileStateFull>,
    required: true,
  },
  type: {
    type: String as PropType<TrialProsperiType>,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = reactive(
  useSelf({
    type: props.type,
  }),
);

const promoTrial = reactive(self.promoTrial);

self.init();
</script>
