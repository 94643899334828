import { getCache, setCache } from '@/helpers/persistent-cache';
import ReminderNtfModal from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-reminder-modal/components/reminder-ntf-modal/ReminderNtfModal.vue';
import ReminderTrialModal from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-reminder-modal/components/reminder-trial-modal/ReminderTrialModal.vue';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';
import { useModal } from '@/services/modal/modal';

let modal: {
  showModal: any;
  hideModal: any;
} | null = null;

const MODAL_CACHE_KEY = 'reminder';

export async function show() {
  const isShown = !!getCache(MODAL_CACHE_KEY);

  if (isShown) {
    return;
  }

  const split = useSplit('reminder');
  await split.init();

  if (split.variant === 'ntf-v2') {
    modal = useModal(() => {
      return {
        component: ReminderNtfModal,
        props: {},
        on: {},
      };
    });
    modal.showModal();
    setCache(MODAL_CACHE_KEY, '1');
  } else if (split.variant === 'trf-v2') {
    modal = useModal(() => {
      return {
        component: ReminderTrialModal,
        props: {},
        on: {},
      };
    });
    modal.showModal();
    setCache(MODAL_CACHE_KEY, '1');
  }
}

export function hide() {
  if (modal) {
    modal.hideModal();
    modal = null;
  }
}

export const reminderModal = {
  show,
  hide,
};
