import { computed, reactive, ref } from 'vue';

import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { MyProfileStateShort } from '@/pages/my-profile/store/my-profile-state';
import { dispatch } from '@/store/store-helper';

type UseSelfArg = {
  //
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const profileShort = ref<MyProfileStateShort>();

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------
  const avatarId = computed<string>(() => {
    return profileShort.value?.avatar.id ?? '';
  });

  // Method --------------------------------------------------------------------

  async function init() {
    profileShort.value = await dispatch('actionMyProfileGetShort');
  }

  return reactive({
    avatarId,
    init,
  });
}
