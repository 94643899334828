export const SETTINGS_PROFILE_DEFAULT_HEIGHT_FROM = 55;
export const SETTINGS_PROFILE_DEFAULT_HEIGHT_TO = 80;
export const SETTINGS_PROFILE_HEIGHT_MIN = 51;
export const SETTINGS_PROFILE_HEIGHT_MAX = 83;
export const SETTINGS_MY_PROFILE_DEFAULT_HEIGHT = 65;

export const SETTINGS_PROFILE_DEFAULT_AGE_FROM = 30;
export const SETTINGS_PROFILE_DEFAULT_AGE_TO = 95;
export const SETTINGS_PROFILE_AGE_MIN = 18;
export const SETTINGS_PROFILE_AGE_MAX = 105;

export const SETTINGS_PROFILE_MAX_PHOTOS_COUNT = 6;

// ( Los Angeles by default )
export const SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE = 34.0522346496582;
export const SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE = -118.2436828613281;
export const SETTINGS_PROFILE_DEFAULT_CITY_NAME = 'Los Angeles';
export const SETTINGS_PROFILE_DEFAULT_COUNTRY_NAME = 'USA';

// It means that we'll divide image sizes to this value,
// e.g.: if we have a 800x600 image, then it'll be 80x60
// in blurred version because of scalar 10.
// More scalar - worse quality.
export const SETTINGS_BLUR_IMAGE_INTENSITY = 10;

export const SETTINGS_NO_PHOTO_ID = 'b29757381e2c1c1a2942492accf17f2b';
export const SETTINGS_MAX_IMAGE_SIZE_BYTES = 1024 * 1024 * 20;
export const SETTINGS_FAQ_URL = 'https://support.meetmyage.com/support/home';
export const SETTINGS_BLOG_URL = 'https://meetmyage.com/lp/blog';
export const SETTINGS_LOCAL_DATING_URL = 'https://meetmyage.com/lp/local-dating';
export const SETTINGS_FAQ_PHOTO_URL =
  'https://gdx-help.freshdesk.com/support/solutions/articles/103000004891-what-kind';

// https://dev.solidgate.com/developers/documentation/solid-payment-form
export const SETTINGS_SOLID_FORM_API_URL = 'https://cdn.charge-auth.com/js/form.js';

// Common date format, more: https://day.js.org/docs/en/display/format
export const SETTINGS_DATE_FORMAT = 'MMMM D, YYYY';

// How much time we give to the server for sync, before we will make next related request.
export const SETTINGS_SYNC_COOLDOWN = 2000;

// Auto show trial popup delay
export const SETTINGS_POPUP_AUTOSHOW_DELAY_MS = 3000;

export const SETTINGS_DEFAULT_CURRENCY = 'USD';

export const AD_ID_SEARCH_MIN_SIZE = '3273910612';
export const AD_ID_SEARCH_MAX_SIZE = '1432137917';
export const AD_ID_PROFILE_MIN_SIZE = '5185758082';
export const AD_ID_PROFILE_MAX_SIZE = '9768797081';
export const AD_ID_CHAT_MIN_SIZE = '9863369690';
export const AD_ID_ACTIVITY_MIN_SIZE = '9560117360';

// no way to match booster and possibility, that's way we need booster id
export const BOOSTER_TOP_PROFILE_ID = 'top-profile-new';
export const BOOSTER_TOP_PROFILE_PLUS_ID = 'top-profile-plus';

export const CACHE_KEY_PERSISTED_STATE = 'store';

export const PACKAGE_PREMIUM_DEFAULT_1_MONTH_ID = 'pkg-premium-standard-taxes-1m-v7';

export const PACKAGE_PREMIUM_OFFER_EARLY_UPGRADE_ID = 'early-upgrade-taxes';
