import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  reminder: string;
  header1: string;
  header2: string;
  text2: string;
  showOffer: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Worried about renewal?',
    reminder: 'You’ll get a reminder',
    header1: 'Create your profile',
    header2: 'Today',
    text2: 'Enjoy Full Premium Access!',
    showOffer: 'Show limited offers',
  },
  es: {
    header: '¿Preocupado por la renovación?',
    reminder: 'Recibirás un recordatorio',
    header1: 'Crea tu perfil',
    header2: 'Hoy',
    text2: '¡Disfruta de acceso Premium completo!',
    showOffer: 'Mostrar ofertas limitadas',
  },
};

export { messages };
