<template>
  <ModalClose>
    <iframe v-if="self.url" class="h-[512px] w-80" :src="self.url" frameborder="0"></iframe>
  </ModalClose>
</template>

<script lang="ts" setup>
import ModalClose from '@/components/modal/modal-close/ModalClose.vue';

import { useSelf } from './hooks/use-self/use-self';

const self = useSelf({});

self.init();
</script>
