import { AccountStatus } from '@project-gd-x/dating-site-contracts/src/gen/gdx/common/v1/profile_pb';
import {
  CameBackEvent,
  OnboardingCompleteClientEvent,
  ProfileVisitedEvent,
  RouteVisitedEvent,
  WebPushClickClientEvent,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/client_events_pb';
import {
  GetFacebookTrackingParamsResponse,
  StoreClientEventRequest,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/myprofile/v1/my_profile_api_pb';
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { ActionContext } from 'vuex';

import { SettingsState } from '@/pages/settings/store/settings-state';
import {
  deactivateAccount,
  getFacebookTrackingParams,
  getIsSafe,
  getState,
  reactivateAccount,
  storeClientEvent,
} from '@/services/api/my-profile/my-profile-api';
import { registerPushToken } from '@/services/api/notification-push/notification-push-api';
import {
  getFreeCreditsFeaturesStatus,
  listPopUpPromoPackages,
} from '@/services/api/payment/payment-api';
import {
  getFreeCreditsFeaturesStatusFrom,
  getStateFrom,
} from '@/store/modules/common/common-helper/common-helper';
import {
  CommonState,
  CommonStateCreditsFeatures,
  CommonStateIndex,
} from '@/store/modules/common/common-state';
import { ActionFunction, commit } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

declare module '@/store/store-helper' {
  interface Action {
    actionGetState: ActionFunction<never, CommonStateIndex>;
    actionCommonGetAvailablePromos: ActionFunction<never, CommonGetAvailablePromosReturn>;
    actionCommonRegisterPushToken: ActionFunction<string, void>;
    actionCommonStoreClientEventWebPushClick: ActionFunction<string, void>;
    actionCommonStoreClientEventRouteVisited: ActionFunction<string, void>;
    actionCommonStoreOnboardingCompleteClientEvent: ActionFunction<number, void>;
    actionCommonStoreProfileVisitedClientEvent: ActionFunction<string, void>;
    actionCommonGetFacebookTrackingParams: ActionFunction<
      never,
      ExtractClassFields<GetFacebookTrackingParamsResponse>
    >;
    actionCommonGetIsSafe: ActionFunction<string, boolean>;
    actionCommonDeactivateAccount: ActionFunction<number, void>;
    actionCommonReactivateAccount: ActionFunction<never, void>;
    actionCommonGetFreeCreditsFeaturesStatus: ActionFunction<never, CommonStateCreditsFeatures>;
    actionCommonStoreClientEventCameBack: ActionFunction<string, void>;
  }
}

export type CommonGetAvailablePromosReturn = {
  type: PromoType;
  packages: ExtractClassFields<PremiumPackage>[];
};

export async function actionGetState(): Promise<CommonStateIndex> {
  const response = await getState();
  const stateData = await getStateFrom(response);
  commit('mutationCommonSetState', stateData);

  return stateData;
}

export async function actionCommonGetAvailablePromos(): Promise<CommonGetAvailablePromosReturn> {
  const response = await listPopUpPromoPackages();

  commit('mutationCommonSetPromoModalType', response.promoType);
  commit('mutationCommonSetPromoModalPackages', response.packages);

  return {
    type: response.promoType,
    packages: response.packages,
  };
}

export async function actionCommonStoreClientEventWebPushClick(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  type: string,
): Promise<void> {
  const webPuchClickEvent = new WebPushClickClientEvent({
    webPushType: type,
  });
  // const request = new StoreClientEventRequest().setWebPushClick(webPuchClickEvent);
  const request = new StoreClientEventRequest({
    event: {
      case: 'webPushClick',
      value: webPuchClickEvent,
    },
  });

  await storeClientEvent(request);
}

export async function actionCommonStoreOnboardingCompleteClientEvent(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  engagementScore: number,
): Promise<void> {
  const onboardingCompleteClientEvent = new OnboardingCompleteClientEvent({
    engagementScore,
  });

  const request = new StoreClientEventRequest({
    event: {
      case: 'onboardingComplete',
      value: onboardingCompleteClientEvent,
    },
  });

  await storeClientEvent(request);
}

export async function actionCommonStoreProfileVisitedClientEvent(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  visitedUserId: string,
): Promise<void> {
  const profileVisitedEvent = new ProfileVisitedEvent({
    visitedUserId,
  });
  const request = new StoreClientEventRequest({
    event: {
      case: 'visit',
      value: profileVisitedEvent,
    },
  });

  await storeClientEvent(request);
}

export async function actionCommonRegisterPushToken(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  token: string,
): Promise<void> {
  await registerPushToken(token);
}

export async function actionCommonStoreClientEventRouteVisited(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  route: string,
): Promise<void> {
  const routeVisitedEvent = new RouteVisitedEvent({
    route,
  });

  const request = new StoreClientEventRequest({
    event: {
      case: 'routeVisited',
      value: routeVisitedEvent,
    },
  });

  try {
    await storeClientEvent(request);
  } catch {
    console.log('Failed to store route visited event');
  }
}

export async function actionCommonGetFacebookTrackingParams(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
): Promise<ExtractClassFields<GetFacebookTrackingParamsResponse>> {
  return await getFacebookTrackingParams();
}

export async function actionCommonGetIsSafe(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  eventName: string,
): Promise<boolean> {
  const response = await getIsSafe(eventName);

  return response.isSafe;
}

export async function actionCommonDeactivateAccount(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SettingsState, unknown>,
  duration: number,
): Promise<void> {
  await deactivateAccount(duration);
  commit('mutationCommonSetAccountStatus', AccountStatus.DEACTIVATED);
}

export async function actionCommonReactivateAccount(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SettingsState, unknown>,
): Promise<void> {
  await reactivateAccount();
  commit('mutationCommonSetAccountStatus', AccountStatus.ACTIVE);
}

export async function actionCommonGetFreeCreditsFeaturesStatus(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<SettingsState, unknown>,
): Promise<CommonStateCreditsFeatures> {
  const response = await getFreeCreditsFeaturesStatus();
  const features = getFreeCreditsFeaturesStatusFrom(response);

  commit('mutationCommonSetCreditsFeatures', features);

  return features;
}

export async function actionCommonStoreClientEventCameBack(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { state }: ActionContext<CommonState, unknown>,
  landingUrl: string,
): Promise<void> {
  const cameBackEvent = new CameBackEvent({
    landingUrl,
  });

  const request = new StoreClientEventRequest({
    event: {
      case: 'cameBack',
      value: cameBackEvent,
    },
  });

  try {
    await storeClientEvent(request);
  } catch {
    console.log('Failed to store came back event');
  }
}
