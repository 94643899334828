import { reactive } from 'vue';

import { paymentPurchase } from '@/components/payment/helpers/payment-method/payment-method';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';

type UseSelfArg = {
  //
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------
  const { price, pkg } = useSubscriptionPromoTrial(() => {});

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function showOffer() {
    paymentPurchase({
      pkg: pkg.value,
    });

    customEventTrigger({
      event: 'reminder_trial_modal_offer_click',
    });
  }

  function init() {
    customEventTrigger({
      event: 'reminder_trial_modal_show',
    });
  }

  return reactive({
    price,
    showOffer,
    init,
  });
}
