import { I18n } from '@/services/i18n';

type Message = {
  expires: string;
  min: string;
  sec: string;
  paySafe: string;
  hint: string;
  policy: string;
  getPremium: string;
};

const messages: I18n<Message> = {
  en: {
    expires: 'Discount expires in',
    min: 'min',
    sec: 'sec',
    paySafe: 'Pay safe and secure',
    hint: 'By clicking “GET -50% PREMIUM” you agree to enroll in a {label} subscription to https://meetmyage.com/ service. The {label} trial costs {packageSelectedPriceFormatted}. If you don’t cancel during your {label} trial, your subscription will automatically renew until you cancel and you will be billed {packageSelectedPriceNextFormatted} every {label}. Payments will be charged from the card you specified here. You can cancel your subscription by contacting our customer support team via email at',
    policy: 'Membership and Refund Policy',
    getPremium: 'GET -50% PREMIUM',
  },
  es: {
    expires: 'El descuento expira en',
    min: 'min',
    sec: 'seg',
    paySafe: 'Pague de forma segura y protegida',
    hint: 'Al hacer clic en “OBTENER -50% PREMIUM” acepta inscribirse en una suscripción {label} al servicio de https://meetmyage.com/. La prueba {label} cuesta {packageSelectedPriceFormatted}. Si no cancela durante su prueba {label}, su suscripción se renovará automáticamente hasta que cancele y se le facturará {packageSelectedPriceNextFormatted} cada {label}. Los pagos se cargarán de la tarjeta que especificó aquí. Puede cancelar su suscripción poniéndose en contacto con nuestro equipo de atención al cliente por correo electrónico a',
    policy: 'Política de Membresía y Reembolso',
    getPremium: 'OBTENER -50% PREMIUM',
  },
};

export { messages };
