import { I18n } from '@/services/i18n';

type Message = {
  header: string;
  paymentDetails: string;
  terms: string;
  choose: string;
  creditCard: string;
  or: string;
  expressCheckout: string;
};

const messages: I18n<Message> = {
  en: {
    header: 'Payment methods',
    paymentDetails: 'Payment details',
    terms: 'Please, accept the terms first.',
    choose: 'Choose your payment method:',
    creditCard: 'Credit card',
    or: 'or',
    expressCheckout: 'Express checkout',
  },
  es: {
    header: 'Métodos de pago',
    paymentDetails: 'Detalles de pago',
    terms: 'Por favor, acepta los términos primero.',
    choose: 'Elige tu método de pago:',
    creditCard: 'Tarjeta de crédito',
    or: 'o',
    expressCheckout: 'Pago rápido',
  },
};

export { messages };
