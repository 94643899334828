<template>
  <ModalPortal :is-teleport-enabled="false" :background-scroll="false">
    <div class="flex items-center justify-center h-full">
      <div
        class="bg-white overflow-hidden shadow-modal-close rounded-lg flex flex-col mx-3 max-w-lg min-w-[320px]"
      >
        <div class="flex justify-start p-4" @click="emit('close')">
          <i class="icon icon-close w-5 h-5 text-[20px] text-dark-100 cursor-pointer"></i>
        </div>

        <div class="relative mx-auto">
          <div class="w-[92px] rounded-full overflow-hidden">
            <RetinaPhoto
              :photo-id="self.avatarId"
              :width="92"
              :height="92"
              group-ratio="avatar-square"
            >
            </RetinaPhoto>
          </div>
          <img
            class="border-4 rounded-full border-white absolute box-content -bottom-3 -right-3"
            src="../../assets/img/premium.svg?url"
            width="40"
            height="40"
            alt=""
          />
        </div>

        <div class="text-headline6 text-premium font-semibold text-center mt-7">
          {{ t('header') }}
        </div>
        <div class="text-headline6 text-dark font-semibold text-center">{{ t('reminder') }}</div>

        <div class="mx-3 mt-4 h-64 flex gap-2">
          <div class="w-12 relative">
            <div class="w-1 h-[230px] bg-premium-500 absolute left-1/2 -translate-x-1/2 top-4 z-10">
              <div class="w-full bg-premium h-[114px] rounded-b-full"></div>
            </div>
            <div
              class="absolute w-12 h-12 bg-premium-500 rounded-full left-1/2 -translate-x-1/2 top-[69px]"
            ></div>
            <img
              class="absolute top-0 left-1/2 -translate-x-1/2 z-20"
              src="../../assets/img/step-1.svg?url"
              alt=""
            />
            <img
              class="absolute top-[77px] left-1/2 -translate-x-1/2 z-20"
              src="../../assets/img/step-2.svg?url"
              alt=""
            />
            <img
              class="absolute top-[147px] left-1/2 -translate-x-1/2 z-20"
              src="../../assets/img/step-3.svg?url"
              alt=""
            />
            <img
              class="absolute top-[217px] left-1/2 -translate-x-1/2 z-20"
              src="../../assets/img/step-4.svg?url"
              alt=""
            />
          </div>
          <div>
            <div class="text-button1 font-semibold text-dark-200 mt-[6px]">{{ t('header1') }}</div>

            <div class="text-dark mt-[48px] h-[38px]">
              <div class="text-button1 font-semibold">{{ t('header2') }}</div>
              <div class="text-caption font-normal">{{ t('text2') }}</div>
            </div>

            <div class="text-dark mt-8 h-[38px]">
              <div class="text-button1 font-semibold">
                <slot name="step3-header"></slot>
              </div>
              <div class="text-caption font-normal">
                <slot name="step3-content"></slot>
              </div>
            </div>

            <div class="text-dark mt-[34px] h-[38px]">
              <div class="text-button1 font-semibold">
                <slot name="step4-header"></slot>
              </div>
              <div class="text-caption font-normal">
                <slot name="step4-content"></slot>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-10 uppercase h-12 mx-4 mb-5 bg-premium text-white rounded text-button1 font-semibold flex items-center justify-center cursor-pointer hover:bg-opacity-90"
          @click="emit('show-offer')"
        >
          <slot name="next-btn">{{ t('showOffer') }}</slot>
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import ModalPortal from '@/components/modal/ModalPortal.vue';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const emit = defineEmits(['close', 'show-offer']);

const { t } = useI18n({ messages });

const self = useSelf({});

self.init();
</script>
