import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import TextPage from '@/pages/text-page/TextPage.vue';

import { RouteName } from './types';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'root',
    path: '/',
    component: () => import('@/pages/homepage/Homepage.vue'),
    meta: {
      isGuest: true,
      title: `Online dating app for people who love to live - ${
        import.meta.env.VUE_APP_PROJECT_NAME
      }`,
      metaDescription: `Join ${
        import.meta.env.VUE_APP_PROJECT_NAME
      } to chat with single people in your area and meet new friends. Sign up for free to discover lots of new people, chosen just for you.`,
    },
  },
  {
    name: 'homepage',
    path: '/homepage',
    redirect: '/',
  },
  {
    path: '/retention',
    name: 'retentionChat',
    component: () => import('@/pages/retention/components/retention-chat/RetentionChat.vue'),
  },
  {
    path: '/retention-upgrade',
    name: 'retentionUpgrade',
    component: () => import('@/pages/retention/components/retention-upgrade/RetentionUpgrade.vue'),
  },
  {
    path: '/signup',
    component: () => import('@/pages/signup/Signup.vue'),
    children: [
      {
        name: 'signupAccount',
        path: 'account',
        component: () => import('@/pages/signup/components/account/EmailAccount.vue'),
        meta: {
          isGuest: true,
        },
      },
      {
        name: 'signupAccountGender',
        path: 'account/gender/:myGender/:matchGender',
        component: () => import('@/pages/signup/components/account/EmailAccount.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'externalAccount',
        path: 'external/:myGender/:matchGender/:provider',
        component: () => import('@/pages/signup/components/account/ExternalAccount.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'externalFacebook',
        path: 'external/facebook',
        component: () =>
          import(
            '@/pages/signup/components/account/components/facebook-register/FacebookRegister.vue'
          ),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'externalAccountGender',
        path: 'external/gender/:provider',
        component: () => import('@/pages/signup/components/account/ExternalAccount.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'signupAccountSteps',
        path: 'account/:stepName',
        component: () => import('@/pages/signup/components/account/EmailAccount.vue'),
        meta: {
          isGuest: true,
        },
      },
      {
        name: 'signupCheckInbox',
        path: 'check-inbox',
        component: () => import('@/pages/signup/components/check-inbox/CheckInbox.vue'),
      },
      {
        name: 'signupFillOutProfile',
        path: 'fill-out-profile',
        component: () => import('@/pages/signup/components/fill-out-profile/FillOutProfile.vue'),
      },
      {
        name: 'signupMyProfile',
        path: 'my-profile',
        redirect: {
          name: 'signupMyProfileSteps',
          params: {
            stepName: 'my-photo',
          },
        },
      },
      {
        name: 'signupMyProfileSteps',
        path: 'my-profile/:stepName',
        component: () => import('@/pages/signup/components/profile/MyProfile.vue'),
      },
      {
        name: 'signupFilledInMatch',
        path: 'filled-in-match',
        component: () => import('@/pages/signup/components/filled-in-match/FilledInMatch.vue'),
      },
    ],
  },
  {
    path: '/signin',
    component: () => import('@/pages/signin/Signin.vue'),
    children: [
      {
        name: 'facebook',
        path: 'facebook',
        component: () => import('@/pages/signin/components/signin/Signin.vue'),
        meta: {
          isGuest: true,
        },
        props: () => ({
          provider: 'facebook',
        }),
      },
      {
        name: 'google',
        path: 'google',
        component: () => import('@/pages/signin/components/signin/Signin.vue'),
        meta: {
          isGuest: true,
        },
        props: () => ({
          provider: 'google',
        }),
      },
      {
        name: 'signin',
        path: '',
        component: () => import('@/pages/signin/components/signin/Signin.vue'),
        meta: {
          isGuest: true,
        },
      },
      // Special route for this task https://gdx.myjetbrains.com/youtrack/issue/GDP-2293/frontend-Create-catalog-for-organic-SEO
      {
        name: 'signin-lp-back',
        path: 'lp',
        component: () => import('@/pages/signin/components/signin/Signin.vue'),
        meta: {
          isGuest: true,
        },
        props: {
          back: () => {
            window.history.back();
          },
        },
      },
      {
        name: 'signinRestorePassword',
        path: 'restore-password',
        component: () => import('@/pages/signin/components/restore-password/RestorePassword.vue'),
        meta: {
          isGuest: true,
        },
      },
      {
        name: 'signinConfirmPassword',
        path: 'confirm-password/:sessionHash',
        component: () => import('@/pages/signin/components/confirm-password/ConfirmPassword.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'signinConfirmPasswordSuccess',
        path: 'confirm-password-success',
        component: () =>
          import('@/pages/signin/components/confirm-password-success/ConfirmPasswordSuccess.vue'),
        meta: {
          isGuest: true,
        },
      },
      {
        name: 'signinAutologin',
        path: 'autologin/:autologinHash',
        component: () => import('@/pages/signin/components/autologin/Autologin.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
      {
        name: 'signinAutologinRedirect',
        path: 'autologin/:autologinHash/redirect/:redirectUrl',
        component: () => import('@/pages/signin/components/autologin/Autologin.vue'),
        meta: {
          isGuest: true,
        },
        props: true,
      },
    ],
  },
  {
    path: '/search',
    component: () => import('@/pages/search/Search.vue'),
    children: [
      {
        name: 'search',
        path: '',
        component: () => import('@/pages/search/components/search-grid/SearchGrid.vue'),
        meta: {
          canBeSaved: true,
        },
      },
      {
        name: 'searchPromo',
        path: 'promo',
        component: () => import('@/pages/search/components/search-grid/SearchGrid.vue'),
      },
      {
        name: 'searchReward',
        path: 'reward',
        component: () => import('@/pages/search/components/search-grid/SearchGrid.vue'),
      },
    ],
  },
  {
    path: '/profile',
    component: () => import('@/pages/profile/Profile.vue'),
    children: [
      {
        name: 'profileView',
        path: ':userId/view/:icon?',
        component: () => import('@/pages/profile/components/profile-view/ProfileView.vue'),
        props: (route) => ({
          userId: route.params.userId,
          icon: route.params.icon,
        }),
        meta: {
          canBeSaved: true,
        },
      },
    ],
  },
  {
    path: '/chat',
    component: () => import('@/pages/chat/Chat.vue'),
    children: [
      {
        name: 'chatList',
        path: 'list',
        component: () => import('@/pages/chat/components/chat-list/ChatList.vue'),
        meta: {
          canBeSaved: true,
        },
      },
      {
        name: 'chatView',
        path: ':chatId',
        component: () => import('@/pages/chat/components/chat-view/ChatView.vue'),
        props: true,
        meta: {
          canBeSaved: true,
        },
      },
    ],
  },
  {
    path: '/my-profile',
    component: () => import('@/pages/my-profile/MyProfile.vue'),
    children: [
      {
        name: 'myProfileView',
        path: 'view',
        component: () => import('@/pages/my-profile/components/my-profile-view/MyProfileView.vue'),
      },
      {
        name: 'myProfileEdit',
        path: 'edit',
        component: () => import('@/pages/my-profile/components/my-profile-edit/MyProfileEdit.vue'),
      },
      {
        name: 'myProfileDeactivated',
        path: 'deactivated',
        component: () =>
          import('@/pages/my-profile/components/my-profile-deactivated/MyProfileDeactivated.vue'),
        meta: {
          isGuest: true,
        },
      },
      {
        name: 'myProfileDeactivatedMail',
        path: 'deactivated/mail',
        component: () =>
          import(
            '@/pages/my-profile/components/my-profile-deactivated-mail/MyProfileDeactivatedMail.vue'
          ),
      },
      {
        name: 'myProfileBanned',
        path: 'banned',
        component: () =>
          import('@/pages/my-profile/components/my-profile-banned/MyProfileBanned.vue'),
        meta: {
          isGuest: true,
        },
      },
    ],
  },
  {
    path: '/payment',
    component: () => import('@/pages/payment/Payment.vue'),
    children: [
      {
        name: 'paymentPurchasePremium',
        path: 'purchase-premium',
        component: () => import('@/pages/payment/components/purchase/Purchase.vue'),
        props: {
          purchase: 'premium',
        },
      },
      {
        name: 'paymentPurchasePremiumGold',
        path: 'purchase-premium-gold',
        component: () => import('@/pages/payment/components/purchase/Purchase.vue'),
        props: {
          purchase: 'premium-gold',
        },
      },
      {
        name: 'paymentPurchaseCredits',
        path: 'purchase-credits',
        component: () => import('@/pages/payment/components/purchase/Purchase.vue'),
        props: {
          purchase: 'credits',
        },
      },
      {
        name: 'paymentSubscriptionUpgraded',
        path: 'subscription-upgraded',
        component: () =>
          import(
            '@/pages/payment/components/payment-subscription-upgraded/PaymentSubscriptionUpgraded.vue'
          ),
      },
      {
        name: 'paymentSuccess',
        path: 'success',
        component: () =>
          import('@/pages/payment/components/payment-status/payment-success/PaymentSuccess.vue'),
      },
      {
        name: 'paymentFail',
        path: 'fail/:orderId?',
        component: () =>
          import('@/pages/payment/components/payment-status/payment-fail/PaymentFail.vue'),
        props: true,
      },
      {
        name: 'paymentFailReturn',
        path: 'fail-return/:returnRouteName',
        component: () =>
          import('@/pages/payment/components/payment-status/payment-fail/PaymentFail.vue'),
        props: true,
      },
      {
        name: 'paymentSubscriptionFail',
        path: 'fail/:orderId?',
        component: () =>
          import('@/pages/payment/components/payment-status/payment-fail/PaymentFail.vue'),
        props: { isSubscription: true },
      },
      {
        name: 'paymentWaiting',
        path: 'waiting/:orderId',
        component: () =>
          import('@/pages/payment/components/payment-status/payment-waiting/PaymentWaiting.vue'),
        props: true,
      },
      {
        name: 'paymentPaypalInstant',
        path: 'paypal/instant',
        component: () =>
          import(
            '@/pages/payment/components/payment-status/payment-paypal-instant/PaymentPaypalInstant.vue'
          ),
      },
      {
        name: 'paymentPaypalScheduled',
        path: 'paypal/scheduled',
        component: () =>
          import(
            '@/pages/payment/components/payment-status/payment-paypal-scheduled/PaymentPaypalScheduled.vue'
          ),
      },
      {
        name: 'paymentSubscription',
        path: 'subscription',
        component: () => import('@/pages/payment/components/subscription/Subscription.vue'),
      },
      {
        name: 'paymentBillingHistory',
        path: 'billing-history',
        component: () =>
          import('@/pages/payment/components/payment-billing-history/PaymentBillingHistory.vue'),
      },
      {
        name: 'paymentCredits',
        path: 'credits',
        component: () => import('@/pages/payment/components/payment-credits/PaymentCredits.vue'),
      },
    ],
  },
  {
    path: '/activity',
    component: () => import('@/pages/activity/Activity.vue'),
    children: [
      {
        name: 'activity',
        path: ':source',
        component: () => import('@/pages/activity/components/activity/Activity.vue'),
        props: true,
        meta: {
          canBeSaved: true,
        },
      },
    ],
  },
  {
    path: '/settings',
    component: () => import('@/pages/settings/Settings.vue'),
    children: [
      {
        name: 'settingsList',
        path: '',
        component: () => import('@/pages/settings/components/settings-list/SettingsList.vue'),
      },
      {
        name: 'settingsAccount',
        path: 'account',
        component: () => import('@/pages/settings/components/settings-account/SettingsAccount.vue'),
      },
      {
        name: 'settingsAccountProfile',
        path: 'account-profile',
        component: () =>
          import('@/pages/settings/components/settings-account-profile/SettingsAccountProfile.vue'),
      },
      {
        name: 'settingsSubscription',
        path: 'subscription',
        component: () =>
          import('@/pages/settings/components/settings-subscription/SettingsSubscription.vue'),
      },
      {
        name: 'settingsPaymentMethod',
        path: 'payment-method',
        component: () =>
          import('@/pages/settings/components/settings-payment-method/SettingsPaymentMethod.vue'),
        props: true,
      },
      {
        name: 'settingsSubscriptionList',
        path: 'subscription-list',
        component: () =>
          import(
            '@/pages/settings/components/setting-subscription-action-list/SettingsSubscriptionList.vue'
          ),
      },
      {
        name: 'settingsSubscriptionSuspend',
        path: 'settings-subscription-suspend',
        component: () =>
          import(
            '@/pages/settings/components/settings-subscription-suspend/SettingsSubscriptionSuspend.vue'
          ),
      },
      {
        name: 'settingsSubscriptionSuspendPeriod',
        path: 'settings-subscription-suspend-period',
        component: () =>
          import(
            '@/pages/settings/components/settings-subscription-suspend-period/SettingsSubscriptionSuspendPeriod.vue'
          ),
      },
      {
        name: 'settingsSubscriptionSuspendSuccess',
        path: 'settings-subscription-suspend-success',
        component: () =>
          import(
            '@/pages/settings/components/settings-subscription-suspend-success/SettingsSubscriptionSuspendSuccess.vue'
          ),
      },
      {
        name: 'settingsSubscriptionRenewSuccess',
        path: 'settings-subscription-renew-success',
        component: () =>
          import(
            '@/pages/settings/components/settings-subscription-renew-success/SettingsSubscriptionRenewSuccess.vue'
          ),
      },
      {
        name: 'settingsPolicies',
        path: 'policies',
        component: () =>
          import('@/pages/settings/components/settings-policies/SettingsPolicies.vue'),
      },
      {
        name: 'settingsBlockedUsers',
        path: 'blocked-users',
        component: () =>
          import('@/pages/settings/components/settings-blocked-users/SettingsBlockedUsers.vue'),
      },
    ],
  },
  {
    name: 'textPage',
    path: '/text-page/:pageId',
    component: TextPage,
    meta: {
      isGuest: true,
    },
    props: (route) => ({ pageId: route.params.pageId }),
  },
  {
    name: 'textPageRaw',
    path: '/text-page-raw/:pageId',
    component: TextPage,
    meta: {
      isGuest: true,
    },
    props: (route) => ({ pageId: route.params.pageId, isCloseVisible: false }),
  },
  {
    name: 'errorNotFound',
    path: '/error/not-found',
    component: () => import('@/pages/error/error-not-found/ErrorNotFound.vue'),
    meta: {
      isGuest: true,
    },
  },
  {
    name: 'errorProfileNotFound',
    path: '/error/error-profile-not-found',
    component: () => import('@/pages/error/error-profile-not-found/ErrorProfileNotFound.vue'),
  },
  {
    name: 'errorChatNotFound',
    path: '/error/error-chat-not-found',
    component: () => import('@/pages/error/error-chat-not-found/ErrorChatNotFound.vue'),
  },
  {
    name: 'confirmEmail',
    path: '/confirm-email/:confirmHash',
    redirect: '/signin/confirm-email/:confirmHash',
  },
  {
    name: 'confirmEmailAutologin',
    path: '/confirm-email/:confirmHash/autologin/:autologinHash',
    redirect: '/signin/confirm-email/:confirmHash/autologin/:autologinHash',
  },
  {
    name: 'сonfirmEmailAutologinRedirect',
    path: '/confirm-email/:confirmHash/autologin/:autologinHash/redirect/:redirectUrl',
    redirect: 'signin/confirm-email/:confirmHash/autologin/:autologinHash/redirect/:redirectUrl',
  },
  {
    path: '/connection/likes',
    redirect: {
      name: 'activity',
      params: { source: 'incoming' },
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'errorNotFound' as RouteName },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
