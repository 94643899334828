import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';

import { hideNotificationAll } from '@/components/notification/helpers/notification-shower/notification-shower';
import { ntfModal } from '@/components/ntf-modal/helpers/ntf-modal/ntf-modal';
import {
  SubscriptionFeature,
  SubscriptionPromoModalData,
  SubscriptionPromoModalOptions,
} from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import SubscriptionPromoModal from '@/components/subscription-promo-modal/SubscriptionPromoModal.vue';
import { hasPremium } from '@/helpers/feature/feature';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { memoize } from '@/helpers/memoize/memoize';
import { hasCache, setCache } from '@/helpers/persistent-cache';
import { trackPaymentPromo } from '@/helpers/track/track-payment/track-payment';
import { isPopoverModalSource } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-popover-trial/helpers/popover-trial/popover-trial';
import SubscriptionPromoPopoverTrial from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-popover-trial/SubscriptionPromoPopoverTrial.vue';
import { isWelcomeTrialPopupAvailable } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/helpers/trial-popup/trial-popup';
import {
  getTrialProsperiType,
  isTrialProsperiAvailable,
  showTrialProsperiModal,
} from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/trial-prosperi-modal/trial-prosperi-modal';
import { pushRoute } from '@/router';
import { useSplitNtf } from '@/services/growth-book/hooks/use-split-ntf/use-split-ntf';
import { hideConcreteModal, isModalVisible, useModal } from '@/services/modal/modal';
import { SETTINGS_POPUP_AUTOSHOW_DELAY_MS } from '@/settings';
import { commit, dispatch, getStore } from '@/store/store-helper';

let isLoading = false;
export async function showSubscriptionPromoModal(
  feature: SubscriptionFeature | undefined,
  source: PromoShowPurchaseSource,
  options?: SubscriptionPromoModalOptions,
): Promise<void> {
  // Prevent opening 2 or more modals of the `promo-modal`.
  if (isModalVisible(SubscriptionPromoPopoverTrial) || isModalVisible(SubscriptionPromoModal)) {
    return;
  }

  if (!isLoading) {
    isLoading = true;
    await dispatch('actionCommonGetAvailablePromos');

    // await Promise.all([
    //   dispatch('actionCommonGetAvailablePromos'),
    //   dispatch('actionPaymentListPaymentMethods'),
    // ]);

    const promoType = getStore().state.common.promoModal.type;

    const splitNtf = useSplitNtf();
    await splitNtf.init();

    if (splitNtf.variant.value !== 'def' && promoType === PromoType.DISCOUNT) {
      ntfModal.show({
        feature,
        source,
      });
    } else {
      if (
        !isWelcomeTrialPopupAvailable() &&
        (await isTrialProsperiAvailable({
          promoType,
          source,
        }))
      ) {
        showTrialProsperiModal({
          myProfileFull: await memoize(dispatch)('actionMyProfileGetFull'),
          type: getTrialProsperiType({
            source,
          }),
        });
      } else {
        const popoverModal = useModal(() => ({
          component: SubscriptionPromoPopoverTrial,
          props: {
            source,
            promoModalData: {
              userReceiver: options?.userReceiver,
            } as SubscriptionPromoModalData,
          },
        }));

        const promoModal = useModal(() => ({
          component: SubscriptionPromoModal,
          props: {
            defaultPackage:
              options?.defaultPackage ?? getStore().state.common.promoModal.packages[0],
          },
        }));

        if (isPopoverModalSource(source)) {
          popoverModal.showModal();
        } else {
          if (promoType === PromoType.INVALID) {
            pushRoute('paymentPurchasePremium');
          } else {
            commit('mutationCommonShowPromoModal', {
              feature,
            });
            promoModal.showModal();
          }
        }
      }

      if (promoType === PromoType.TRIAL) {
        // TODO: move tracking to their components
        if (isWelcomeTrialPopupAvailable()) {
          trackPaymentPromo({
            from: source,
            popup: 'premium-welcome-trial',
            type: 'premium',
          });
        } else {
          trackPaymentPromo({
            from: source,
            popup: 'premium-trial',
            type: 'premium',
          });
        }
      } else if (promoType === PromoType.DISCOUNT) {
        trackPaymentPromo({
          from: source,
          popup: 'premium-discount',
          type: 'premium',
        });
      } else if (promoType === PromoType.INVALID) {
        trackPaymentPromo({
          from: source,
          popup: 'premium',
          type: 'premium',
        });
      }
    }

    isLoading = false;
    hideNotificationAll();
  }
}

export function hideSubscriptionPromoModal(): void {
  hideConcreteModal(SubscriptionPromoModal);
}

const KEY = 'after_onboarding_trial_popup_shown';

function isAfterOnboardingTrialPopupShown(): boolean {
  return hasCache(KEY);
}

export function setAfterOnboardingTrialPopupShown(): void {
  setCache(KEY, '1');
}

let timeoutId = 0;

export async function showDelayedTrialPromoModal(): Promise<void> {
  timeoutId = window.setTimeout(() => {
    if (!hasPremium()) {
      if (!isAfterOnboardingTrialPopupShown()) {
        setAfterOnboardingTrialPopupShown();

        showSubscriptionPromoModal(undefined, 'timeout-after-onboarding');
      }
    }
  }, SETTINGS_POPUP_AUTOSHOW_DELAY_MS);
}

export function clearDelayedTrialPromoModal() {
  if (timeoutId !== 0) {
    clearTimeout(timeoutId);
    timeoutId = 0;
  }
}
