import { I18n } from '@/services/i18n';

type Message = {
  header3: string;
  text3: string;
  header4: string;
  text4: string;
};

const messages: I18n<Message> = {
  en: {
    header3: 'Day 5',
    text3: 'Get a reminder when your trial ends.',
    header4: 'Day 7',
    text4: 'Your Premium package will start.',
  },
  es: {
    header3: 'Día 5',
    text3: 'Recibe un recordatorio cuando finalice tu prueba.',
    header4: 'Día 7',
    text4: 'Tu paquete Premium comenzará.',
  },
};

export { messages };
