<template>
  <div class="mb-6 mt-16 overflow-hidden" :class="'swiper-gallery-container' + self.id">
    <div class="swiper-wrapper flex">
      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub1') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text1')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/prem.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub2') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text2')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/heart.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub3') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text3')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/search.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub4') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text4')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/unlim.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub5') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text5')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/eye.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub6') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text6')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/boost.svg?url" alt="" />
        </div>
      </div>

      <div class="flex swiper-slide">
        <div class="flex flex-col justify-between flex-grow">
          <div class="text-white text-button1 font-semibold">{{ t('sub7') }}</div>
          <div class="text-white text-caption font-medium" v-html="t('text7')"></div>
        </div>
        <div class="w-32 flex -mr-4">
          <div class="overflow-hidden rounded-full h-16 w-16 border-4 border-white -mr-4">
            <RetinaPhoto
              :photo-id="myProfileFull.photos[0]?.id ?? ''"
              :width="56"
              :height="56"
              format="png"
            />
          </div>
          <img class="h-16 w-16" src="../../assets/img/ads.svg?url" alt="" />
        </div>
      </div>
    </div>
    <div
      class="mt-4 flex gap-1 swiper-bullets"
      :class="'swiper-gallery-pagination' + self.id"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import 'swiper/swiper-bundle.css';

import Swiper, { Autoplay, Pagination } from 'swiper';
import { onMounted, PropType } from 'vue';

import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

Swiper.use([Pagination, Autoplay]);

const props = defineProps({
  feature: {
    type: String as PropType<SubscriptionFeature>,
    default: undefined,
  },
  myProfileFull: {
    type: Object as PropType<MyProfileStateFull>,
    required: true,
  },
});

const { t } = useI18n({ messages });

const self = useSelf({
  feature: props.feature,
});

onMounted(() => {
  self.init();
});
</script>

<style lang="scss">
.swiper-bullets {
  .active {
    opacity: 1;
  }
}
</style>
