import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { ProfileGender } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { reminderModal } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-reminder-modal/helpers/show-reminder-modal/show-reminder-modal';
import { TrialProsperiType } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/helpers/type/type';
import TrialProsperiModal from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-prosperi-modal/TrialProsperiModal.vue';
import { useModal } from '@/services/modal/modal';
import { dispatch } from '@/store/store-helper';

type TrialProsperiModalArg = {
  myProfileFull: MyProfileStateFull;
  type: TrialProsperiType;
};
export function showTrialProsperiModal({ myProfileFull, type }: TrialProsperiModalArg): void {
  useModal(() => ({
    component: TrialProsperiModal,
    props: {
      myProfileFull,
      type,
    },
    on: {
      close: () => {
        reminderModal.show();
      },
    },
  })).showModal();
}

type GetTrialProsperiTypeArg = {
  source: PromoShowPurchaseSource;
};
export function getTrialProsperiType(arg: GetTrialProsperiTypeArg): TrialProsperiType {
  if (arg.source === 'chat-send-message') {
    return 'message';
  } else if (
    arg.source === 'search-insufficient-likes' ||
    arg.source === 'profile-insufficient-likes'
  ) {
    return 'like';
  } else {
    return 'trial';
  }
}

type IsTrialProsperiAvailableArg = {
  promoType: PromoType;
  source: PromoShowPurchaseSource;
};
export async function isTrialProsperiAvailable({ promoType, source }: IsTrialProsperiAvailableArg) {
  const sourceList: PromoShowPurchaseSource[] = [
    'backend',
    'chat-send-message',
    'search-insufficient-likes',
    'profile-insufficient-likes',
  ];
  if (promoType === PromoType.TRIAL && sourceList.includes(source)) {
    const myProfileFull = await dispatch('actionMyProfileGetFull');

    if (myProfileFull.gender === ProfileGender.MALE) {
      return true;
    }
  }

  return false;
}
