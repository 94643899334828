import { computed, reactive, ref } from 'vue';

import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';

type UseSelfArg = {
  //
};

export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  function showOffer() {
    showSubscriptionPromoModal(SubscriptionFeature.UNLIMITED_MESSAGES, 'reminder');
    customEventTrigger({
      event: 'reminder_ntf_modal_offer_click',
    });
  }

  function init() {
    customEventTrigger({
      event: 'reminder_ntf_modal_show',
    });
  }

  return reactive({
    showOffer,
    init,
  });
}
