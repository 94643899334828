<template>
  <ReminderLayout
    @close="emit('close')"
    @show-offer="
      emit('close');
      self.showOffer();
    "
  >
    <template #step3-header>{{ t('header3') }}</template>
    <template #step3-content>{{ t('text3') }}</template>
    <template #step4-header>{{ t('header4') }}</template>
    <template #step4-content>{{ t('text4') }}</template>
    <template #next-btn> Try 7-days trial for {{ self.price }} </template>
  </ReminderLayout>
</template>

<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true, isForceAllowedModal: true };
</script>

<script lang="ts" setup>
import ReminderLayout from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-reminder-modal/components/reminder-layout/ReminderLayout.vue';
import { useI18n } from '@/services/i18n';

import { useSelf } from './hooks/use-self/use-self';
import { messages } from './i18n';

const emit = defineEmits(['close']);

const { t } = useI18n({ messages });

const self = useSelf({});

self.init();
</script>
