import NtfModal from '@/components/ntf-modal/NtfModal.vue';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { reminderModal } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-reminder-modal/helpers/show-reminder-modal/show-reminder-modal';
import { useModal } from '@/services/modal/modal';

let modal: {
  showModal: any;
  hideModal: any;
} | null = null;

type ShowArg = {
  feature: SubscriptionFeature | undefined;
  source: PromoShowPurchaseSource;
};

export function show(arg: ShowArg) {
  modal = useModal(() => ({
    component: NtfModal,
    props: {
      feature: arg.feature,
      source: arg.source,
    },
    on: {
      close: () => {
        reminderModal.show();
      },
    },
  }));

  modal.showModal();
}

export function hide() {
  if (modal) {
    modal.hideModal();
    modal = null;
  }
}

export const ntfModal = {
  show,
  hide,
};
