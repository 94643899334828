import { computed, reactive, ref } from 'vue';

import {
  paymentPurchase,
  paymentSelect,
} from '@/components/payment/helpers/payment-method/payment-method';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { memoize } from '@/helpers/memoize/memoize';
import { MyProfileStateFull } from '@/pages/my-profile/store/my-profile-state';
import { isWelcomeTrialPopupAvailable } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/helpers/trial-popup/trial-popup';
import { PaymentStateCard, PaymentStatePackagePremium } from '@/pages/payment/store/payment-state';
import { useSplitNtf } from '@/services/growth-book/hooks/use-split-ntf/use-split-ntf';
import { dispatch } from '@/store/store-helper';

type UseSelfArg = {
  source: PromoShowPurchaseSource;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const profileFull = ref<MyProfileStateFull>();
  const cardList = ref<PaymentStateCard[]>();
  const isWelcomePopupAvailable = ref(isWelcomeTrialPopupAvailable());

  // Hook ----------------------------------------------------------------------
  const splitNtf = useSplitNtf();

  // Computed ------------------------------------------------------------------
  const cardDefault = computed(() => {
    return cardList.value?.[0];
  });

  const isProsperiSource = computed(() => {
    return (
      arg.source === 'profile-insufficient-likes' ||
      arg.source === 'search-insufficient-likes' ||
      arg.source === 'chat-send-message' ||
      arg.source === 'my-profile' ||
      arg.source === 'backend'
    );
  });

  // Method --------------------------------------------------------------------
  function setIsWelcomePopupAvailable(value: boolean) {
    isWelcomePopupAvailable.value = value;
  }

  function purchasePkg(pkg: PaymentStatePackagePremium) {
    paymentPurchase({
      pkg,
    });
  }

  function selectPkg(pkg: PaymentStatePackagePremium) {
    paymentSelect({
      pkg,
    });
  }

  async function fetchProfileFull() {
    profileFull.value = await memoize(dispatch)('actionMyProfileGetFull');
  }

  async function fetchCardList() {
    cardList.value = await dispatch('actionPaymentListPaymentCards');
  }

  async function init() {
    await fetchProfileFull();
    await fetchCardList();
    await splitNtf.init();
  }

  return reactive({
    profileFull,
    isWelcomePopupAvailable,
    splitNtf,
    cardDefault,
    isProsperiSource,
    setIsWelcomePopupAvailable,
    purchasePkg,
    selectPkg,
    init,
  });
}
